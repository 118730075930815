import axios from "axios";

const api = axios.create({
    // baseURL: "https://petfichas.pethappy.cl/api/",
    baseURL: `${process.env.VUE_APP_BASE_URL}/api/`,
    timeout: 100000, // Request timeout
    header: {
        "Access-Control-Allow-Origin": "*",
    },
});

class Api {
    constructor() {}

    findClientByRut(rut) {
        return api({
            url: `/cliente/${rut}`,
            method: "get",
        });
    }

    findClientByRutSimple(rut) {
        return api({
            url: `/clienteb/${rut}`,
            method: "get",
        });
    }

    getSpecies() {
        return api({
            url: "/species",
            method: "get",
        });
    }

    getReasons() {
        return api({
            url: "/reasons-s-v",
            method: "get",
        });
    }

    findSchedule(data) {
        return api({
            url: `find-schedules/`,
            method: "post",
            data: data,
        });
    }

    save(data) {
        return api({
            url: "store",
            method: "post",
            data: data,
        });
    }

    getInitData(isSpecialty) {
        return api({
            url: `/get-init-data/${isSpecialty}`,
            method: "get",
        });
    }

    getAppointment(data) {
        return api({
            url: `schedule/get-appointment`,
            method: "post",
            data: data,
        });
    }

    cancelAppointment(data) {
        return api({
            url: `schedule/cancel-appointment`,
            method: "post",
            data: data,
        });
    }
    getDataBySpecialty(specialty) {
        return api({
            url: `get-data-by-specialty/${specialty}/`,
            method: "get",
        });
    }
    getDataByBranch(specialty,isSpecialty) {
        return api({
            url: `get-data-by-branch/${specialty}/${isSpecialty}`,
            method: "get",
        });
    }

    getVeterinaryByBranchAndSpecialty(branch, specialty) {
        return api({
            url: `get-veterinary-by-branch-and-specialty/${branch}/${specialty}/`,
            method: "get",
        });
    }
}

export { Api as default };
